@import "./assets/css/fontawesome.css";
@import "./assets/css/animate.css";
@import "./assets/css/slick.css";
/* @import "./assets/css/owl.carousel.min.css"; */
@import "./style.css";
@import "./assets/css/responsive.css";

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700 !important;
}

nav ul li a {
  font-size: .86rem !important;
}

nav ul li.nav-btn a {
  padding: 10px 20px !important;
}
.btn-sm {
  padding: 0.5rem 1.5rem !important;
}

.btn-primary {
  border-color: #fbbd18 !important;
}

.bg-primary {
  background-color: #f0931e !important;
}

button:focus {
  box-shadow: none !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  border-color: #f0931e !important;
}

iframe {
  border: none !important;
}

.video-modal .modal-content {
  border: none !important;
  background: transparent !important;
}

.video-modal .modal-content .modal-body {
  padding: 0 !important;
}
.dark-bg {
  background: #121212;
}

.profile-page {
  color: white;
  padding-top: 130px;
}

.wallet-action-wrap {
  margin-bottom: 50px;
}
.first-row {
  gap: 2%;
}

.auth-card,
.token-info-card {
  border-radius: 0.5rem;
  background: #222222;
}
.auth-card {
  flex: 1 1 29%;
  margin-top: 40px;
}
.token-info-card {
  flex: 1 1 69%;
}

.auth-card :where(h2, h4) {
  text-transform: uppercase;
}

.auth-card img {
  border-radius: 50%;
  margin-top: -90px;
}

.auth-card h4 {
  font-size: 0.9rem;
  color: gray;
  font-weight: 600 !important;
  margin-top: 4.2rem;
}

.auth-card h2 {
  font-size: 1.6rem;
  color: #e2e1e1;
  font-weight: 400 !important;
}

.auth-card button {
  color: #ffffffbd;
}

.token-info-card .token-info {
  background: linear-gradient(
    135deg,
    rgba(251, 190, 24, 1) 0%,
    rgba(240, 146, 30, 1) 67%,
    rgba(240, 146, 30, 1) 100%
  );
  border-radius: 0.5rem;
  margin-top: -50px;
}
.token-info-card .token-info p {
  color: #4a4a4a;
  margin-bottom: 0.5rem;
}
.token-info-card .token-info p b {
  font-weight: 600;
}

.token-info-card .balance-title,
.token-info-card .balance-value {
  font-weight: 400 !important;
}

.token-info-card .balance-title {
  color: rgb(255, 255, 255);
}
.token-info-card .balance-value {
  color: green;
  font-weight: 400;
}

.token-info-card .balance-value small {
  color: gray;
  font-weight: 500;
  font-size: 1.7rem;
}

@media screen and (max-width: 1100px) {
  .auth-card {
    flex: 1 1 39%;
  }
  .token-info-card {
    flex: 1 1 59%;
  }
}

@media screen and (max-width: 800px) {
  .auth-card {
    flex: 1 1 100%;
  }
  .token-info-card {
    flex: 1 1 100%;
    margin-top: 1.5rem;
  }

  .token-info-card .token-info {
    margin-top: 0;
  }
}

/* -------------- */

.total-stat-card {
  color: #e2e1e1;
  margin-top: 3rem;
}

.total-stat-card hr {
  border-color: rgba(226, 226, 226, 0.2);
}

.total-stat-card .total-figure img {
  width: 120px;
  height: 120px;
  border-radius: 6px;
  margin-top: -45px;
}

.total-stat-card .total-figure,
.current-figure {
  border-radius: 0.5rem;
  background: #222222;
}

.total-stat-card .total-figure .amount-info h3 {
  font-weight: 400 !important;
}

.total-stat-card .badge {
  background-color: #f0931e !important;
  font-size: 0.8rem;
}

.total-stat-card .current-figure h3 {
  font-weight: 400 !important;
}

@media screen and (max-width: 600px) {
  .total-stat-card .total-figure .amount-info h3 {
    text-align: right;
    font-size: 1.2rem;
  }

  .total-stat-card .total-figure img {
    width: 90px;
    height: 90px;
    margin-right: 1rem;
  }

  .total-stat-card .current-figure h3 {
    font-size: 1.2rem;
  }

  .total-stat-card .card-bottom {
    font-size: 0.8rem;
  }
}

/* ------------------------------------ */

.reward-card {
  border-radius: 0.5rem;
  background: #222222;
  color: #ffffffcf;
  margin-bottom: 2rem;
}

.reward-card > img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.reward-card .name {
  font-size: 1.6rem;
  font-weight: 600 !important;
}

.reward-card hr {
  border-color: #f0931e;
  border-width: 2px;
}

.reward-card .msg {
  font-size: 1rem;
}

.reward-card .total-earned {
  font-size: 1rem;
  font-weight: 600;
}

.reward-card .total-distributed {
  font-size: 1rem;
  font-weight: 400;
}

.reward-card .status {
  font-size: 1rem;
  font-weight: 400;
}

.reward-card p {
  margin-bottom: 0.5rem;
}

.reward-card p:last-child {
  margin-bottom: 0;
}

.reward-card button {
  background: linear-gradient(
    135deg,
    rgba(251, 190, 24, 1) 0%,
    rgba(240, 146, 30, 1) 67%,
    rgba(240, 146, 30, 1) 100%
  ) !important;
  border: none !important;
}

.reward-card button:hover {
  background: linear-gradient(
    135deg,
    rgba(251, 190, 24, 1) 0%,
    rgba(240, 146, 30, 1) 67%,
    100%
  ) !important;
}

.reward-card button:focus {
  box-shadow: none !important;
  outline: none !important;
}

/* -------------------------- */

section.calculator {
  border-radius: 0.5rem;
  background: #222222;
  color: #ffffffcf;
  margin-top: 3rem;
}
section.calculator .calculator-area {
}
section.calculator .calculator-area .calculator-header {
  border-radius: 0.5rem;
  width: calc(100% - 40px);
  margin-inline: auto;
  position: relative;
  top: -43px;
  background: linear-gradient(
    135deg,
    rgba(251, 190, 24, 1) 0%,
    rgba(240, 146, 30, 1) 67%,
    rgba(240, 146, 30, 1) 100%
  ) !important;
}

section.calculator .calculator-area .calculator-header button {
  background: #ffffff45 !important;
  border: none !important;
  box-shadow: none !important;
}
section.calculator .calculator-area .calculator-body .left-area input {
  background-color: #3c3b3b;
  border: none;
  font-size: 1.5rem;
  border-radius: 0.5rem;
  color: white;
  caret-color: white;
  text-align: right;
}
section.calculator .calculator-area .calculator-body .left-area .title {
  font-size: 1.2rem;
}
section.calculator .calculator-area .calculator-body .left-area .actions {
  gap: 1rem;
}
section.calculator
  .calculator-area
  .calculator-body
  .left-area
  .actions
  button {
  background: #3c3b3b !important;
  border: none !important;
  box-shadow: none !important;
}
section.calculator .calculator-area .result-card {
  background: #3c3b3b;
  height: 100%;
  border-radius: 0.5rem;
}

section.calculator .calculator-area .result-card .result-body {
  flex: 1 1 auto;
  color: rgba(240, 146, 30, 1);
  font-size: 2rem;
}

section.calculator .calculator-area .result-card .result-header small {
  font-size: 1.1rem;
}

section.calculator .calculator-area .result-card .result-header p {
  font-size: 1.3rem;
}

/* -------- */
section.history-table {
  border-radius: 0.5rem;
  background: #222222;
  color: #ffffffcf;
  margin-top: 3rem;
}

section.history-table .history-header {
  border-radius: 0.5rem;
  width: calc(100% - 40px);
  margin-inline: auto;
  position: relative;
  top: -33px;
  background: linear-gradient(
    135deg,
    rgba(251, 190, 24, 1) 0%,
    rgba(240, 146, 30, 1) 67%,
    rgba(240, 146, 30, 1) 100%
  ) !important;
}

section.history-table .history-body table thead th {
  color: rgba(212, 211, 211, 0.671);
}

section.history-table .history-body table tbody :where(td, th) {
  color: rgb(240, 240, 240);
}

.table thead th {
  border-bottom: 1px solid #dee2e647 !important;
}

.table td,
.table th {
  border-top: none !important;
}

/* ----------- */

.glitter-mines-page {
  color: white;
}

.glitter-mines-page .page-header {
  background: #222222;
  padding: 130px 0 90px;
}

.glitter-mines-page .page-header h1 {
  font-weight: 500 !important;
}

.glitter-mines-page .page-header h4 {
  font-weight: 400 !important;
  font-size: 1rem;
}

.glitter-mines-page .page-header :where(h1, h4) {
  color: rgb(192, 192, 192);
}

.glitter-mines-page .page-header .components-controllers .search-input-wrap {
  width: 180px;
}

.glitter-mines-page
  .page-header
  .components-controllers
  .search-input-wrap
  :where(input, input:focus) {
  background-color: #3c3b3b;
  border: none;
  border-radius: 0.5rem;
  color: white;
  caret-color: white;
}

.glitter-mines-page
  .page-header
  .components-controllers
  :where(.right-controls, .left-controls) {
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.glitter-mines-page .page-header .components-controllers .right-controls {
  width: 65%;
}

.sortby-select {
  background-color: #3c3b3b;
  border-radius: 0.5rem;
  color: rgb(216, 216, 216);
  height: 46px;
  max-width: 180px;
}
.icon-box {
  background-color: #3c3b3b;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.icon-box.active {
  background: #f0931e !important;
}

.sortby-select select {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: rgb(216, 216, 216);
  padding-right: 12px !important;
}

input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ffffff !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #f6aa35 !important;
  background-color: #f6aa35 !important;
  box-shadow: none !important;
}

.custom-switch .custom-control-label::after {
  background-color: #56595c !important;
}

.glitter-mines-page .page-body {
  margin-top: -50px;
}

.gliiter-mine-card {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 2rem;
}

.gliiter-mine-card .card-top {
  background: linear-gradient(
    135deg,
    rgba(251, 190, 24, 1) 0%,
    rgba(240, 146, 30, 1) 67%,
    rgba(240, 146, 30, 1) 100%
  ) !important;
}
.gliiter-mine-card .card-top h4 {
  font-weight: 600 !important;
}
.gliiter-mine-card :where(.card-middle, .card-bottom) {
  background: #222222;
}
.gliiter-mine-card .card-middle p {
  margin-bottom: 0.5rem;
  color: #e0dedec2;
}
.apy-row span:first-child {
  border-bottom: 1px dotted white;
}

.gliiter-mine-card .card-middle button {
  border-radius: 30px;
}

.gliiter-mine-card .card-bottom {
  border-top: 1px solid rgba(181, 181, 181, 0.1747);
}

.gliiter-mine-card .card-bottom .badge {
  background-color: #f6aa35 !important;
  border-radius: 19px;
}

.cursor-pointer {
  cursor: pointer;
}
.details-select {
  position: relative;
}

.details-select .icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.details-select select {
  background: transparent !important;
  color: white !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

@media screen and (max-width: 991.98px) {
  .search-input-wrap,
  .sortby-select,
  .right-controls {
    width: 100% !important;
    max-width: 100% !important;
  }
}

/* ---------------- */

.transactions-page {
  padding-top: 130px;
}

.alert-c {
  position: relative;
}

.alert-c svg {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
}

.page-title {
  font-weight: 600 !important;
}

.btn-transparent {
  background-color: #222222 !important;
  border-color: #222222 !important;
}

.btn-transparent:is(:hover, :active, :focus) {
  background-color: #3f3e3d !important;
  border-color: #3f3e3d !important;
}

.trans-pre-actions {
  gap: 1rem;
}

.transactions-card {
  background-color: #222222;
  color: white;
}

.select-filters {
  gap: 1rem;
}

.all-filter {
  width: 46px;
  height: 46px;
  background-color: #474646;
  display: grid;
  place-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.filter-select {
  background-color: #474646;
  border-color: #474646;
  color: white;
  outline: none;
  cursor: pointer;
  max-width: 180px;
}

.form-control {
  background-color: #474646 !important;
  border-color: #474646 !important;
  color: white !important;
  outline: none !important;
  caret-color: white !important;
  box-shadow: none !important;
}

.btn-search {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  box-shadow: none !important;
  outline: none !important;
  padding-block: 10px !important;
}

.transaction-item {
  border-bottom: 1px solid #c1bdbd3d;
}

.transaction-item .item-header .left-area .form-check input {
  width: 22px !important;
  height: 22px !important;
  position: relative;
  top: -1px;
}

.transaction-item .item-header .left-area .status-icon-info .info .date {
  font-size: 0.9rem;
}

.form-check {
  accent-color: #f6aa35;
}

.transaction-item .item-body .details-part .symbol {
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: yellowgreen;
  display: grid;
  place-items: center;
}

.orange-bg {
  background-color: #f6aa35 !important;
}
.transaction-item .item-body .details-part .details-info p {
  margin-block: 0.5rem !important;
  color: #e0dedec2;
}

.transaction-item .item-body .details-part .details-info .view-transaction {
  font-size: 0.8rem;
  cursor: pointer;
}

.transaction-item .item-body .details-part .details-info .badges {
  gap: 1rem;
}

.transaction-item .badge-success {
  background-color: rgb(68, 205, 50) !important;
}

.transaction-item .badge-light {
  color: black !important;
  background-color: #fcfcfcc2 !important;
}

.transaction-item .bg-light {
  color: black !important;
  background-color: #fcfcfcc2 !important;
}

.alert {
  padding: .75rem 1.95rem .75rem 1.25rem !important;
}
/* ----------- */

.privateSale-page {
  background:url("assets/images/banner.jpg");
  filter : opacity(50%);
  color: white;
}

.privateSale-page .page-header {
  padding: 130px 0 90px;
}

.privateSale-page .page-header h1 {
  font-weight: 500 !important;
}

.privateSale-page .page-header h4 {
  font-weight: 400 !important;
  font-size: 1rem;
}

.privateSale-page .page-header :where(h1, h4) {
  color: rgb(192, 192, 192);
}

.privateSale-page .page-header .components-controllers .search-input-wrap {
  width: 180px;
}

.privateSale-page
.page-header
.components-controllers
.search-input-wrap
:where(input, input:focus) {
  background-color: #3c3b3b;
  border: none;
  border-radius: 0.5rem;
  color: white;
  caret-color: white;
}

.privateSale-page
.page-header
.components-controllers
:where(.right-controls, .left-controls) {
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.privateSale-page .page-header .components-controllers .right-controls {
  width: 65%;
}
